import Lightbox from 'modules/gallery-lightbox';
import Gallery from 'modules/gallery';
import 'mypage/sticky-header';
import 'mypage/list';

// non-editable fundraiser mypage stuff
import MyPageWebSockets from 'websockets/websocket.js';
MyPageWebSockets('fundraiser');

Lightbox.lightboxID = '#mypage_photo_overlay';
Lightbox.photoID = '#mypage_photo_overlay_image';
Lightbox.setup();

Gallery.setup();
